
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html, body {
	width: 100%;
	height: 100%;
}

$login-border-color: #4978b4;
$gradient-color-1: #66a2ec;
$gradient-color-2: #0e2344;

.login-page {
    padding-top: 23%;
    .form-signin {
        max-width: 45%;
        min-width: 460px;
        margin: 0 auto 10px;
        padding: 4px;
        background: $login-border-color;
        border-radius: 4px;
        .panel {
            margin-bottom: 0;
        }
        .panel-heading {
            text-align: center;
            padding: 20px 0;
            background: white;
            span {
                font-weight: bold;
                color: #636363;
                font-size: 36px;
            }
        }
        .panel-body {
            padding: 25px 15px 15px;
        }
        input[name="username"] {
            margin-bottom: 15px;
        }
        .alert-danger {
            color: white;
        }
        .btn.btn-primary {
            background: #578cda;
            padding: 6px 20px;
            font-size: 16px;
            border: 0;
        }
    }
    .alert-danger {
        background-color: #FF3030;
        border-color: #FF3030;
        color: #a94442;
    }
}

body {
	background: $gradient-color-1; /* For browsers that do not support gradients */
	background: -webkit-radial-gradient(circle, $gradient-color-1, $gradient-color-2); /* Safari */
	background: -o-radial-gradient(circle, $gradient-color-1, $gradient-color-2); /* Opera 11.6 to 12.0 */
	background: -moz-radial-gradient(circle, $gradient-color-1, $gradient-color-2); /* Firefox 3.6 to 15 */
	background: radial-gradient(circle, $gradient-color-1, $gradient-color-2); /* Standard syntax */
}
